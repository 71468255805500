<template>
  <div class="route-login right-side min-vh-100">
    <div class="vh-100 px-4 p-md-5">
      <b-button
        type="button"
        variant="link"
        class="px-0 btn-register float-right text-uppercase"
        to="/login"
      >
        Login
        <feather type="arrow-right"></feather>
      </b-button>
      <div class="d-table w-100 h-85 h-md-100">
        <div class="d-table-cell align-middle">
          <b-row>
            <b-col md="10" offset-md="1" class="position-static">
              <h1 class="mb-4">
                Checking Registration Status
              </h1>
              <small class="d-block mt-2 font-size-20 color-blue"><b-spinner label="Loading" class="font-size-18 mr-2"></b-spinner> Please, wait a second or two ...</small>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      auth: state => state.auth,
      payment: state => state.link,
      status: state => state.auth.status,
      user: state => state.user
    }),
    loggedIn () {
      return this.status.loggedIn
    },
    userLoaded () {
      return this.user.status === 'loaded'
    },
    linkid () {
      if (this.payment && this.payment.id && this.payment.type && (this.payment.type === 'payment' || this.payment.type === 'fund')) {
        return this.payment.id
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('alert', {
      setError: 'error',
      clearError: 'clear'
    }),
    ...mapActions('auth', ['logout']),
    ...mapActions('link', ['clear', 'save']),
    ...mapActions('user', ['get']),
    ...mapActions('registration', {
      update: 'updateState'
    }),
    ...mapActions('userOauth', {
      getAuths: 'get'
    }),
    checkUser: function () {
      if (this.user.user && this.user.user.status) {
        if (this.auth.user.user.company) {
          const _status = this.user.user.status.company
          let _verified = true

          if (_status && _status.length > 0) {
            _status.forEach(status => {
              if (status.status !== 'Verified' && _verified) {
                _verified = false
              }
            })
          } else {
            this.update({
              state: 'walkthrough',
              value: {
                type: 'Organisation',
                complete: false
              }
            })
            this.$router.push('/verify/business/one')
          }

          if (_verified) {
            if (this.linkid) {
              if (this.payment.embedded) {
                this.$router.push('/embedded/pay')
              } else {
                this.$router.push('/pay')
              }
            } else {
              this.$router.push('/home')
            }
          } else {
            this.update({
              state: 'walkthrough',
              value: {
                type: 'Organisation',
                complete: false
              }
            })
            this.$router.push('/verify/business/one')
          }
        } else {
          const _status = this.user.user.status.personal
          let _verified = true

          if (_status && _status.length > 0) {
            _status.forEach(status => {
              if (status.status !== 'Verified' && _verified) {
                _verified = false
              }
            })
          } else {
            this.update({
              state: 'walkthrough',
              value: {
                type: 'Individual',
                complete: true
              }
            })
            this.$router.push('/verify/individual/one')
          }

          if (_verified) {
            if (this.linkid) {
              if (this.payment.embedded) {
                this.$router.push('/embedded/pay')
              } else {
                this.$router.push('/pay')
              }
            } else {
              this.$router.push('/home')
            }
          } else {
            this.update({
              state: 'walkthrough',
              value: {
                type: 'Individual',
                complete: true
              }
            })
            this.$router.push('/verify/individual/one')
          }
        }
      } else {
        this.clearError()
        this.setError('Could not retrieve KYC status. Please, try logging in again or contact Truzo support.')
        this.logout()
      }
    }
  },
  created () {
    if (this.loggedIn) {
      if (this.$route.path !== '/') {
        const _path = this.$route.path.split('/')
        const _embedded = this.$route.path.indexOf('embedded') >= 0
        this.clear()
        this.save({
          type: this.$route.query.type === 'fund' ? 'fund' : 'payment',
          embedded: _embedded,
          link: this.$route.path,
          id: _path[_path.length - 1]
        })
      }

      this.getAuths()

      if (this.userLoaded) {
        this.checkUser()
      } else {
        this.get()
      }
    } else {
      this.$router.push('/login')
    }
  },
  watch: {
    userLoaded: function (loaded) {
      if (loaded) {
        this.checkUser()
      }
    }
  }
}
</script>
