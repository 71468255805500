<template>
  <b-modal id="ozowfund-modal" size="lg" centered hide-header hide-footer no-close-on-backdrop no-close-on-esc :hide-backdrop="isEmbedded" :modal-class="isEmbedded ? 'embedded' : null">
    <h4 class="font-weight-400 color-grey mb-3">Ozow Payment</h4>
    <form id="ozowfund_form" ref="ozowfund_form" :action="url" method="GET" target="ozowfund_iframe"></form>
    <iframe style="border: 0px; width: 100%; height: 380px" name="ozowfund_iframe"></iframe>
  </b-modal>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      interval: undefined
    }
  },
  props: {
    url: String,
    id: String,
    reference: String,
    hash: String,
    isWalletFund: Boolean
  },
  computed: {
    ...mapState({
      payments: state => state.payments
    }),
    paymentStatus () {
      return this.payments.status
    },
    isEmbedded () {
      if (this.$route.path.indexOf('embedded') >= 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('payments', {
      pollOzow: 'pollOzow',
      pollOzowFund: 'pollOzowFund'
    }),
    submitForm: function () {
      const self = this
      this.$nextTick(() => {
        setTimeout(function () {
          const _form = document.getElementById('ozowfund_form')
          _form.submit()

          // self.payOzow(this.transaction._id)
          self.startOzowPolling()
        }, 1000)
      })
    },
    startOzowPolling: function () {
      const self = this
      if (this.isWalletFund) {
        self.interval = setInterval(() => {
          self.pollOzowFund({ id: self.id, reference: self.reference, hash: self.hash, interval: self.interval })
        }, 1000)
      } else {
        this.interval = setInterval(() => {
          self.pollOzow({ id: self.id, reference: self.reference, hash: self.hash, interval: self.interval })
        }, 1000)
      }
    }
  },
  watch: {
    paymentStatus (_status) {
      if (_status === 'failed') {
        clearInterval(this.interval)
        this.interval = null

        this.$bvModal.hide('ozowfund-modal')
      } else if (_status === 'paid') {
        clearInterval(this.interval)
        this.interval = null

        this.$bvModal.hide('ozowfund-modal')
      }
    }
  }
}
</script>
