import { transactionService, paymentService, walletFundService } from '../services'

const state = {
  data: null,
  status: null
}

const actions = {
  payEft ({ dispatch, commit }, id) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    transactionService.payEft(id)
      .then(
        actions => {
          if (actions) {
            commit('paid', actions)
            dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed', null)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  payWallet ({ dispatch, commit }, params) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    transactionService.payWallet(params.id, params.saveDetails)
      .then(
        actions => {
          if (actions) {
            commit('paid', actions)
            dispatch('alert/success', 'Thank you for your payment. We have allocated the funds from your wallet to this transaction.', { root: true })
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed', null)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  payNewCard ({ dispatch, commit }, params) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      transactionService.payNewCard(params.id, params.card)
        .then(
          actions => {
            if (actions) {
              if (actions.message === 'Payment processed.') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else if (actions.message === 'Need to do 3D Secure.') {
                commit('secure', actions)
                dispatch('alert/warning', '3D Secure verification is required, redirecting you now.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', actions.message, { root: true })
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  payCard ({ dispatch, commit }, params) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      transactionService.payCard(params.id, params.card, params.saveDetails)
        .then(
          actions => {
            if (actions) {
              console.log(actions.message)
              if (actions.message === 'Payment processed.') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else if (actions.message === 'Need to do 3D Secure.') {
                commit('secure', actions)
                dispatch('alert/warning', '3D Secure verification is required, redirecting you now.', { root: true })
              } else {
                commit('failed', null)
                if (actions.error) {
                  if (Array.isArray(actions.error) && actions.error.length > 0) {
                    dispatch('alert/error', actions.error[0], { root: true })
                  } else {
                    dispatch('alert/error', actions.error, { root: true })
                  }
                } else {
                  dispatch('alert/error', actions.message, { root: true })
                }
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  poll3dSecure ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      transactionService.poll3dSecure(params.id)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              if (actions.response) {
                if (actions.response['SOAP-ENV:Envelope']) {
                  const _envelope = actions.response['SOAP-ENV:Envelope']
                  if (_envelope['SOAP-ENV:Body'] && _envelope['SOAP-ENV:Body'].length > 0) {
                    const _body = _envelope['SOAP-ENV:Body'][0]
                    if (_body['SOAP-ENV:Fault']) {
                      try {
                        const _soapFault = _body['SOAP-ENV:Fault'][0].detail[0]
                        const _ipgResponse = _soapFault['ipgapi:IPGApiOrderResponse'][0]
                        const _error = _ipgResponse['ipgapi:ErrorMessage'][0]
                        commit('failed', null)
                        dispatch('alert/error', _error, { root: true })
                      } catch (error) {
                        console.log(error)
                        commit('failed', null)
                        dispatch('alert/error', 'There was an error processing the payment.', { root: true })
                      }
                    }
                  }
                }
              }
            }
          },
          error => {
            clearInterval(params.interval)

            if (error === 'This transaction already has a payment made.') {
              commit('paid', actions)
              dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
            } else {
              commit('failed', null)
              dispatch('alert/error', error, { root: true })
            }
          }
        )
    } else {
      commit('notFound')
    }
  },
  getOzowUrl ({ dispatch, commit }, id) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    if (id) {
      transactionService.getOzowUrl(id)
        .then(
          url => {
            if (url) {
              commit('loaded', url)
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  payStitch ({ dispatch, commit }, params) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      transactionService.payStitch(params.id, params.hash, params.forcenew)
        .then(
          data => {
            if (data) {
              if (data.status !== 'Paid' && data.url) {
                commit('loaded', data.url)
              } else if (data.status === 'Verifying') {
                params.interval = setInterval(() => {
                  dispatch('pollStitch', params)
                }, 1000)
              } else if (data.status === 'Paid') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', data.message, { root: true })
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error.message ? error.message : error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  pollStitch ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.hash) {
      transactionService.pollStitch(params.hash)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              if (actions.status === 'Complete') {
                clearInterval(params.interval)
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else if (actions.status === 'Failed' ) {
                clearInterval(params.interval)
                let error = actions.status
                if (actions.response && actions.response.response) {
                  const _response = actions.response.response
                  if (_response.errors && _response.errors.length > 0) {
                    error = _response.errors[0].message
                  }
                }

                commit('failed', null)
                dispatch('alert/error', error, { root: true })
              }
            }
          },
          error => {
            clearInterval(params.interval)
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  payOzow ({ dispatch, commit }, id) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    transactionService.payOzow(id)
      .then(
        actions => {
          if (actions) {
            commit('ozow', actions)
            /* dispatch('txnActions/action', { action: 'approve', id: id }, { root: true })
            dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true }) */
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed', null)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  pollOzow ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      transactionService.pollOzow(params.reference, params.hash)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              clearInterval(params.interval)
              if (actions.status === 'Complete') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', 'Instant EFT payment failed. Status received: ' + actions.status, { root: true })
              }
            }
          },
          error => {
            clearInterval(params.interval)
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  prepareTxn ({ dispatch, commit }, id) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    if (id) {
      paymentService.prepareTxn(id)
        .then(
          url => {
            if (url) {
              commit('secure', url)
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  validateTxn ({ dispatch, commit }, txn) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    if (txn) {
      paymentService.validateTxn(txn)
        .then(
          actions => {
            if (actions) {
              console.log(actions.message)
              if (actions.message === 'Payment processed.') {
                commit('paid', actions)
                dispatch('txnActions/action', { action: 'approve', id: txn.id }, { root: true })
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', actions.message, { root: true })
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  pollTxn ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      paymentService.pollTxn(params.id)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              if (actions && actions.status && actions.status !== 'APPROVED: APPROVED') {
                // console.log(actions)
                var error = actions.status
                clearInterval(params.interval)
                commit('failed', error)
                dispatch('alert/error', error, { root: true })
              }
            }
          },
          error => {
            clearInterval(params.interval)

            if (error === 'This transaction already has a payment made.') {
              commit('paid', actions)
              dispatch('txnActions/action', { action: 'approve', id: params.id }, { root: true })
              dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
            } else {
              commit('failed', null)
              dispatch('alert/error', error, { root: true })
            }
          }
        )
    } else {
      commit('notFound')
    }
  },
  clearPoll ({ dispatch, commit }, interval) {
    dispatch('alert/clear', null, { root: true })
    if (interval) {
      clearInterval(interval)
      commit('clear')
      dispatch('alert/warning', 'The payment modal has been closed. Please contact us immediately on 011 486 0692 or info@truzo.com, if the payment was proccesed and you wished to cancel', { root: true })
    }
  },
  clear ({ commit }) {
    commit('clear')
  },
  payEftFund ({ dispatch, commit }, id) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    walletFundService.payEft(id)
      .then(
        actions => {
          if (actions) {
            commit('paid', actions)
            dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed', null)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  payNewCardFund ({ dispatch, commit }, params) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      walletFundService.payNewCard(params.id, params.card)
        .then(
          actions => {
            if (actions) {
              if (actions.message === 'Payment processed.') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else if (actions.message === 'Need to do 3D Secure.') {
                commit('secure', actions)
                dispatch('alert/warning', '3D Secure verification is required, redirecting you now.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', actions.message, { root: true })
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  payCardFund ({ dispatch, commit }, params) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      walletFundService.payCard(params.id, params.card, params.saveDetails)
        .then(
          actions => {
            if (actions) {
              if (actions.message === 'Payment processed.') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else if (actions.message === 'Need to do 3D Secure.') {
                commit('secure', actions)
                dispatch('alert/warning', '3D Secure verification is required, redirecting you now.', { root: true })
              } else {
                commit('failed', null)
                if (actions.error) {
                  if (Array.isArray(actions.error) && actions.error.length > 0) {
                    dispatch('alert/error', actions.error[0], { root: true })
                  } else {
                    dispatch('alert/error', actions.error, { root: true })
                  }
                } else {
                  dispatch('alert/error', actions.message, { root: true })
                }
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  poll3dSecureFund ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      walletFundService.poll3dSecure(params.id)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              if (actions.response) {
                if (actions.response['SOAP-ENV:Envelope']) {
                  const _envelope = actions.response['SOAP-ENV:Envelope']
                  if (_envelope['SOAP-ENV:Body'] && _envelope['SOAP-ENV:Body'].length > 0) {
                    const _body = _envelope['SOAP-ENV:Body'][0]
                    if (_body['SOAP-ENV:Fault']) {
                      try {
                        const _soapFault = _body['SOAP-ENV:Fault'][0].detail[0]
                        const _ipgResponse = _soapFault['ipgapi:IPGApiOrderResponse'][0]
                        const _error = _ipgResponse['ipgapi:ErrorMessage'][0]
                        commit('failed', null)
                        dispatch('alert/error', _error, { root: true })
                      } catch (error) {
                        console.log(error)
                        commit('failed', null)
                        dispatch('alert/error', 'There was an error processing the payment.', { root: true })
                      }
                    }
                  }
                }
              }
            }
          },
          error => {
            clearInterval(params.interval)

            if (error === 'This transaction already has a payment made.') {
              commit('paid', actions)
              dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
            } else {
              commit('failed', null)
              dispatch('alert/error', error, { root: true })
            }
          }
        )
    } else {
      commit('notFound')
    }
  },
  getOzowUrlFund ({ dispatch, commit }, id) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    if (id) {
      walletFundService.getOzowUrl(id)
        .then(
          url => {
            if (url) {
              commit('loaded', url)
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  payStitchFund ({ dispatch, commit }, params) {
    commit('loading')
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      walletFundService.payStitch(params.id, params.hash, params.forcenew)
        .then(
          data => {
            if (data) {
              if (data.status !== 'Paid' && data.url) {
                commit('loaded', data.url)
              } else if (data.status === 'Verifying') {
                params.interval = setInterval(() => {
                  dispatch('pollStitch', params)
                }, 1000)
              } else if (data.status === 'Paid') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', data.message, { root: true })
              }
            } else {
              commit('notFound')
            }
          },
          error => {
            commit('failed', null)
            dispatch('alert/error', error.message ? error.message : error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  pollStitchFund ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.hash) {
      walletFundService.pollStitch(params.hash)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              if (actions.status === 'Complete') {
                clearInterval(params.interval)
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else if (actions.status === 'Failed' ) {
                clearInterval(params.interval)
                let error = actions.status
                if (actions.response && actions.response.response) {
                  const _response = actions.response.response
                  if (_response.errors && _response.errors.length > 0) {
                    error = _response.errors[0].message
                  }
                }

                commit('failed', null)
                dispatch('alert/error', error, { root: true })
              }
            }
          },
          error => {
            clearInterval(params.interval)
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
  payOzowFund ({ dispatch, commit }, id) {
    commit('paying')
    dispatch('alert/clear', null, { root: true })

    walletFundService.payOzow(id)
      .then(
        actions => {
          if (actions) {
            commit('ozow', actions)
            /* dispatch('txnActions/action', { action: 'approve', id: id }, { root: true })
            dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true }) */
          } else {
            commit('notFound')
          }
        },
        error => {
          commit('failed', null)
          dispatch('alert/error', error, { root: true })
        }
      )
  },
  pollOzowFund ({ dispatch, commit }, params) {
    dispatch('alert/clear', null, { root: true })

    if (params && params.id) {
      walletFundService.pollOzow(params.id, params.reference)
        .then(
          actions => {
            if (!actions) {
              commit('notFound')
            } else {
              clearInterval(params.interval)
              if (actions.status === 'Complete') {
                commit('paid', actions)
                dispatch('alert/success', 'Thank you for your payment. We will monitor our bank accounts for the funds.', { root: true })
              } else {
                commit('failed', null)
                dispatch('alert/error', 'Instant EFT payment failed. Status received: ' + actions.status, { root: true })
              }
            }
          },
          error => {
            clearInterval(params.interval)
            commit('failed', null)
            dispatch('alert/error', error, { root: true })
          }
        )
    } else {
      commit('notFound')
    }
  },
}

const mutations = {
  loading (state) {
    state.data = null
    state.status = 'loading'
  },
  loaded (state, data) {
    state.data = data
    state.status = 'loaded'
  },
  paying (state) {
    state.data = null
    state.status = 'paying'
  },
  paid (state, data) {
    state.data = data
    state.status = 'paid'
  },
  secure (state, data) {
    state.data = data
    state.status = '3dsecure'
  },
  ozow (state, data) {
    state.data = data
    state.status = 'ozow'
  },
  notFound (state) {
    state.data = null
    state.status = 'not-found'
  },
  failed (state, data) {
    state.data = data
    state.status = 'failed'
  },
  clear (state) {
    state.data = null
    state.status = null
  }
}

export const payments = {
  namespaced: true,
  state,
  actions,
  mutations
}
